import * as React from 'react';
// import ProductCategories from './modules/views/ProductCategories';
// import ProductSmokingHero from './modules/views/ProductSmokingHero';
// import AppFooter from './modules/views/AppFooter';
// import ProductHero from './modules/views/ProductHero';
// import ProductValues from './modules/views/ProductValues';
// import ProductApp from './modules/views/ProductApp';
// import ForDrivers from './modules/views/ForDrivers';
// import Testimonial from './modules/views/Testimonial';
// import ProductCTA from './modules/views/ProductCTA';
// import AppAppBar from './modules/views/AppAppBar';
import withRoot from './withRoot';
import RoutesJSX from './Routes'

import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
function Index() {
  const routes = createRoutesFromElements(RoutesJSX);
  const router = createBrowserRouter(routes);
  return (
    <React.Fragment>
       <RouterProvider
                        router={router}
                        // fallbackElement={<Spinner />}
                    />
      {/* <AppAppBar />
      <ProductHero />
      <ProductValues />
      <ProductApp />
      <ForDrivers/>
      <ProductCategories />
      <Testimonial />
      <ProductCTA />
      <ProductSmokingHero />
      <AppFooter /> */}
    </React.Fragment>
  );
}

export default withRoot(Index);