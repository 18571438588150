import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import Fallback from './Elements/Fallback'

import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';
import { Outlet } from 'react-router-dom';

const Home = React.lazy(() => import('./modules/Pages/Home'));
const Terms = React.lazy(() => import('./modules/Pages/Terms'));
// const Apply = React.lazy(() => import('./pages/Apply'));
// const Login = React.lazy(() => import('./pages/Login'));
// const DashboardHome = React.lazy(() => import('./pages/Dashboard/Home'));
// const Languages = React.lazy(() => import('./pages/languages'));
// const HomeLayout = React.lazy(() => import('./Layout/HomeLayout.jsx'));
// const LoginLayout = React.lazy(() => import('./Layout/LoginLayout.jsx'));
// const DashboardLayout = React.lazy(() => import('./Layout/DashboardLayout.jsx'));
// const ErrorBoundary = React.lazy(() => import('./components/ErrorBoundary'));

const ZZZSuspense = ({ children }) => {
    return <Suspense fallback={<Fallback />}>{children}</Suspense>
}
function HomeLayout() {
    return (
        <React.Fragment>
            <AppAppBar />
            <Outlet />
            <AppFooter />
        </React.Fragment>
    );
}

const Routes = (
    <>
        <Route path="/" element={<ZZZSuspense><HomeLayout /></ZZZSuspense>} 
        // errorElement={<ErrorBoundary />}
        errorElement={<>Page not found</>}
        >
            <Route index element={<ZZZSuspense><Home /></ZZZSuspense>} />
            <Route path="/terms-and-conditions" element={<ZZZSuspense><Terms /></ZZZSuspense>} />
            {/* <Route index element={<ZZZSuspense><Careers /></ZZZSuspense>} />
      <Route path="/apply/:jobId" element={<ZZZSuspense><Apply /></ZZZSuspense>} />
      <Route path="/dashboard" element={<ZZZSuspense><DashboardLayout /></ZZZSuspense>} >
        <Route index element={<ZZZSuspense><DashboardHome /></ZZZSuspense>} />
      </Route>
      <Route path="/languages" element={<ZZZSuspense><Languages /></ZZZSuspense>} /> */}
        </Route>
        {/* <Route path="/login" element={<ZZZSuspense><LoginLayout /></ZZZSuspense>} >
      <Route index element={<ZZZSuspense><Login /></ZZZSuspense>} />
    </Route> */}
        {/* <Route path="/version" element={<ZZZSuspense><>{process.env.VERSION}</></ZZZSuspense>} /> */}
    </>
);

export default Routes;