import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import havantlogo from '../../assets/havantlogo.png'
import streetlogo from '../../assets/street.png'
import Typography from '../components/Typography';
import { Container } from '@mui/material';
import { NavLink } from "react-router-dom";

const rightLink = {
  fontSize: 16,
  // color: 'common.white',
  ml: 3,
};

function AppAppBar() {
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between', background: 'white', color: 'black' }}>
          <Container sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <Box sx={{ flex: 1 }} /> */}
            <Link
              variant="h6"
              underline="none"
              color="inherit"
              href="/premium-themes/onepirate/"
              sx={{ fontSize: 24, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              {/* <img src={havantlogo} width={150} /> */}
              <img src={streetlogo} width={150} />
            </Link>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', color: 'black' }}>




              <NavLink
                to="/"
                style={{ textDecoration: 'none' }}
              >
                {({ isActive, isPending, isTransitioning }) => <Link
                  variant="h6"
                  underline="none"
                  sx={{ ...rightLink }}
                  color={isActive ? 'primary' : 'black'}
                >
                  <Typography variant="button" sx={{ fontSize: '.7rem' }}> {'HOME'}</Typography>
                </Link>}


              </NavLink>


              <Link
                color="inherit"
                variant="h6"
                underline="none"
                sx={rightLink}
              >
                <Typography variant="button" sx={{ fontSize: '.7rem' }}>{'About us'}</Typography>

              </Link>



              <Link
                color="inherit"
                variant="h6"
                underline="none"
                sx={rightLink}
              >
                <Typography variant="button" sx={{ fontSize: '.7rem' }}>{'Services'}</Typography>
              </Link>
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                sx={rightLink}
              >
                <Typography variant="button" sx={{ fontSize: '.7rem' }}>{'Book online'}</Typography>
              </Link>
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                sx={rightLink}
              >
                <Typography variant="button" sx={{ fontSize: '.7rem' }}>{'Recruitment'}</Typography>
              </Link>
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                sx={rightLink}
              >
                <Typography variant="button" sx={{ fontSize: '.7rem' }}>{'FAQ'}</Typography>
              </Link>
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                sx={rightLink}
              >
                <Typography variant="button" sx={{ fontSize: '.7rem' }}>{'Contact us'}</Typography>
              </Link>
              {/* <Link
              color="inherit"
              variant="h6"
              underline="none"
              sx={rightLink}
            >
              <Typography variant="button" sx={{fontSize:'.7rem'}}>{'T&C'}</Typography>              
            </Link> */}
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
